import React, { useEffect, useState } from "react";
import { File, Gear, Group, Level } from "../../components/common/customIcon";
import { Link } from "react-router-dom";
import { primaryColor, USERACCESSCONTROL, USERDATA } from "../utils/data";
import { Badge } from "antd";
import {
  CommentOutlined,
  LineChartOutlined,
  SoundOutlined,
  UserOutlined,
} from "@ant-design/icons";
import ArrowDownOutlined from "@ant-design/icons/lib/icons/ArrowDownOutlined";

function SideBar(props) {
  const [userAccess] = useState(
    JSON.parse(localStorage.getItem(USERACCESSCONTROL))
  );
  const [showIndictaor, setShowIndictaor] = useState(false);
  let ulContainer = null;
  let userData = JSON.parse(localStorage.getItem(USERDATA));

  useEffect(() => {
    if (ulContainer) {
      setShowIndictaor(ulContainer.clientHeight < ulContainer.scrollHeight);
    }
  }, [ulContainer]);

  if (userAccess) {
    return (
      <div className="side-bar">
        <div className="brand">
          {/* <img src="/groove_logo.jpg" height="70" alt="Groove Logo" /> */}
          <img src="/up-logo.png" height="70" alt="UP-Digital" />
        </div>
        <ul ref={(ref) => (ulContainer = ref)}>
          {userAccess.sideBar.dashboard && (
            <Link
              to={"/admin"}
              className={props.activeSideBar === "dashboard" ? "active" : ""}
            >
              <div className="side-icon">
                <Level size={"20px"} />
              </div>
              Dashboard
            </Link>
          )}
          {userAccess.sideBar.accounts &&
            userAccess.sideBar.accounts.show_list && (
              <Link
                to={"/admin/account"}
                className={props.activeSideBar === "account" ? "active" : ""}
              >
                <div className="side-icon">
                  <Group size={"20px"} />
                </div>
                Accounts
              </Link>
            )}
          {userAccess.sideBar.subscribers && (
            <Link
              to={"/admin/subscribers"}
              className={props.activeSideBar === "subscribers" ? "active" : ""}
            >
              <div className="side-icon">
                <UserOutlined style={{ fontSize: 30 }} />
              </div>
              Subscribers
            </Link>
          )}

          {userAccess.sideBar.products && (
            <Link
              to={"/admin/services"}
              className={props.activeSideBar === "service" ? "active" : ""}
            >
              <div className="side-icon">
                {props.unread && props.unread.products ? (
                  <Badge count={props.unread.products}>
                    <File size={"20px"} />
                  </Badge>
                ) : (
                  <File size={"20px"} />
                )}
              </div>
              Products
            </Link>
          )}

          {userAccess.sideBar.campaigns && (
            <Link
              to={"/admin/campaign"}
              className={props.activeSideBar === "campaign" ? "active" : ""}
            >
              <div className="side-icon">
                <SoundOutlined style={{ fontSize: 30 }} />
              </div>
              Campaign
            </Link>
          )}

          {userAccess.sideBar.finances && (
            <Link
              to={"/admin/finance"}
              className={props.activeSideBar === "finance" ? "active" : ""}
            >
              <div className="side-icon">
                <LineChartOutlined style={{ fontSize: 30 }} />
              </div>
              Finance
            </Link>
          )}
          {userAccess.sideBar.support && (
            <Link
              to={"/admin/support"}
              className={props.activeSideBar === "support" ? "active" : ""}
            >
              <div className="side-icon">
                {props.unread && props.unread.tickets ? (
                  <Badge count={props.unread.tickets}>
                    <CommentOutlined style={{ fontSize: 30 }} />
                  </Badge>
                ) : (
                  <CommentOutlined style={{ fontSize: 30 }} />
                )}
              </div>
              Support
            </Link>
          )}
          {userAccess.sideBar.settings && (
            <Link
              to={"/admin/settings"}
              className={props.activeSideBar === "settings" ? "active" : ""}
            >
              <div className="side-icon">
                <Gear size={"20px"} />
              </div>
              Settings
            </Link>
          )}
          {userAccess.sideBar.profile && (
            <Link
              to={`/admin/account/${userData.userId || userData.data.userId}`}
              className={props.activeSideBar === "account" ? "active" : ""}
            >
              <div className="side-icon">
                <Gear size={"20px"} />
              </div>
              Profile
            </Link>
          )}
        </ul>
        {showIndictaor && (
          <div className="down-indicator">
            {/*<Icon name="chevronDown" type="feather" size={20} />*/}
            <ArrowDownOutlined style={{ color: primaryColor, fontSize: 20 }} />
          </div>
        )}
      </div>
    );
  }
  return <div>''</div>;
}

export default SideBar;
